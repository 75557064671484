import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

const Music = () => (
  <Layout>
    
    <Seo title="Music" bodyClass='st' />

    
    
    <div className="column column--sidebar alignright">
      <h1 className={style.titleLowercase}>Music</h1>
    </div>
	
    <div className="column column--content">




    </div>

  </Layout>
)

export default Music
